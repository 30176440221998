import React, { Component, Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Form } from "react-bootstrap";
import { post } from "../../Utility/HttpService";
import { storage } from "../../Utility/CartStorage";
import { Storage as _storage } from "../../Utility/Storage";
import { getDiscountsForFoodItems } from "../../Utility/URLService";
import { CartItemKey, PassInfoKey, ItemTreeRoot } from "../../Utility/Config";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../../Utility/Spinner";

class PassInfoDialog extends Component {
  //state = {};
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      passNumber: "",
      validation: {
        success: true,
        message: "",
      },
      passValidation: {
        passNumber: "",
        firstName: "",
        lastName: "",
      },

      spinner: {
        show: false,
      },
    };
  }

  //WARNING! To be deprecated in React v17. Use componentDidMount instead.
  componentWillMount() {
    this.showSpinner(true);
  }

  showSpinner = (show) => {
    let spinner = this.state.spinner;
    spinner.show = show;
    this.setState({ spinner });
  };

  handleApplyClick = () => {
    const passNumber = this.state.passNumber;
    const lastName = this.state.lastName;
    const firstName = this.state.firstName;
    const passValidation = this.state.passValidation;

    let foodItems = [];
    if (!passNumber) {
      passValidation.passNumber = " Pass Number is Required";
    } else {
      passValidation.passNumber = "";
    }
    if (!firstName) {
      passValidation.firstName = " First Name is Required";
    } else {
      passValidation.firstName = "";
    }
    if (!lastName) {
      passValidation.lastName = " Last Name is Required";
    } else {
      passValidation.lastName = "";
    }
    this.setState({ passValidation });

    if (passNumber && lastName && firstName) {
      let storedItems = storage.getAllFoodItems();
      if (storedItems.foodItems.length > 0) {
        let storedfoodItems = storedItems.foodItems;
        storedfoodItems.forEach((foodItem) => {
          let customizations = foodItem.customizations;
          delete foodItem.customizations;
          foodItems.push({ customizations, foodItem });
        });
        const restaurantInfo = storage.getRestaurantInfo();

        const passInfoWithFoodItems = {
          passNumber,
          lastName,
          firstName,
          foodItems,
          salesPoint: restaurantInfo.salesPointName,
          rootName: ItemTreeRoot,
        };
        this.getDiscountsforFoodItems(passInfoWithFoodItems);
      } else {
        return false;
      }
    }
  };

  getDiscountsforFoodItems = (passInfoWithFoodItems) => {
    post(getDiscountsForFoodItems, passInfoWithFoodItems)
      .then((result) => result.data)
      .then((data: any) => {
        this.handleDiscountResponses(data);
      })
      .catch((error) => {
        console.log("discount API error block activated");
        let validation = {
          success: false,
          message: "You have not entered a valid pass number",
        };
        this.setState({ validation });
      });
  };

  updateFoodItemsWithSpecialDiscounts = (response) => {
    let foodItems = [];
    response.foodItems.forEach((item) => {
      let foodItem = item.foodItem;
      let specialDiscount = foodItem.specialDiscount;
      if (specialDiscount != null) {
        specialDiscount.actualPrice = foodItem.actualPrice * foodItem.quantity;
        if (
          specialDiscount.discountAmount &&
          specialDiscount.discountAmount > 0
        )
          specialDiscount.discount =
            specialDiscount.actualPrice - specialDiscount.discountAmount;
        else specialDiscount.discount = 0;
      }
      let customizations = item.customizations;
      customizations.forEach((customization) => {
        customization.customizationOptions.forEach((option) => {
          let _specialDiscount = option.specialDiscount;
          if (_specialDiscount != null) {
            _specialDiscount.actualPrice =
              option.actualPrice * foodItem.quantity;
            if (
              _specialDiscount.discountAmount &&
              _specialDiscount.discountAmount > 0
            )
              _specialDiscount.discount =
                _specialDiscount.actualPrice - _specialDiscount.discountAmount;
            else _specialDiscount.discount = 0;
          }
        });
      });
      foodItem.customizations = customizations;
      foodItems.push(foodItem);
    });
    let cartItems = storage.getAllFoodItems();
    cartItems.employeePurchase = response.employeePurchase ? true : false;
    cartItems.foodItems = foodItems;
    cartItems = storage.calculateCartFigures(cartItems);
    _storage.setItem(CartItemKey, JSON.stringify(cartItems));
  };

  SavePassInformationIntoStorage = () => {
    let passInfo = {
      ...this.state,
    };
    delete passInfo.validation;
    _storage.setItem(PassInfoKey, JSON.stringify(passInfo));
  };

  handleDiscountResponses = (response) => {
    let validation = {
      success: true,
      message: "",
    };
    if (!response.validationSuccess) {
      validation.success = false;
      validation.message = response.validationMessage;
      this.setState({ validation });
    } else {
      let specialText = "";
      this.SavePassInformationIntoStorage();
      if (response.specialNames.length > 0) {
        this.updateFoodItemsWithSpecialDiscounts(response);
        if (response.specialNames.length === 1) {
          specialText =
            "The special '" +
            response.specialNames[0].text +
            "' has been applied to your shopping cart.";
        } else {
          let names = "";
          response.specialNames.forEach((special) => {
            if (names) names += ", ";
            names += "'" + special.text + "'";
          });
          specialText =
            "The specials " +
            names +
            " have been applied to your shopping cart.";
        }
      } else {
        specialText = response.nonSpecialMessage;
      }
      this.props.handleSpecialInfo(specialText);
    }
  };

  handleChange = (event) => {
    this.props.checkAndRefreshScreenTime();
    this.setState({ [event.target.id]: event.target.value });
  };

  handleOnEnter = () => {
    this.setState({
      validation: {
        success: true,
        message: "",
      },
    });
  };

  componentDidMount() {
    this.showSpinner(false);
  }

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    return (
      <Fragment>
        {" "}
        <Spinner
          show={this.state.spinner.show}
          value={this.state.spinner}
        ></Spinner>
        <Dialog
          id="passInfoDialog"
          fullWidth={true}
          maxWidth={false}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={this.props.open}
          onEntering={this.handleOnEnter}
        >
          <DialogTitle id="simple-dialog-title" className="text-center">
            <FontAwesomeIcon
              className="modal-close"
              icon={faTimes}
              onClick={this.props.onClose}
            />
            <div id="headerText">Enter Your Pass Information</div>
          </DialogTitle>
          <DialogContent>
            {this.getYourDetails()}
            {!this.state.validation.success && (
              <div className="modal-error-message">
                {this.state.validation.message}
              </div>
            )}
            <div style={{ marginLeft: "0px" }}>
              <Button
                variant="danger"
                type="button"
                onClick={this.handleApplyClick}
              >
                Apply Discount
              </Button>
            </div>
            <div className="spacing-div"></div>
          </DialogContent>
          <DialogActions style={{ display: "table" }}></DialogActions>
        </Dialog>
      </Fragment>
    );
  }

  getYourDetails = () => {
    return (
      <Fragment>
        <div className="your-details">
          <Form.Group controlId="passNumber">
            <Form.Label>Pass Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter Pass Number"
              value={this.state.passNumber}
              onChange={this.handleChange}
            />
            <div className="errorMessage">
              <span className="passError">
                {this.state.passValidation.passNumber}
              </span>
              <span className="required">7-12 Digit Number Required</span>
            </div>
          </Form.Group>
          <Form.Group controlId="firstName">
            <Form.Label>Passholder First Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Passholder First Name"
              value={this.state.firstName}
              onChange={this.handleChange}
            />
            <div className="errorMessage">
              <span className="passError">
                {this.state.passValidation.firstName}
              </span>
              <span className="required">Required</span>
            </div>
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label>Passholder Last Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Passholder Last Name"
              value={this.state.lastName}
              onChange={this.handleChange}
            />
            <div className="errorMessage">
              <span className="passError">
                {this.state.passValidation.lastName}
              </span>
              <span className="required">Required</span>
            </div>
          </Form.Group>
        </div>
      </Fragment>
    );
  };
}
export default PassInfoDialog;
