import React, { Component } from "react";
import Restaurants from "./components/Restaurants/Restaurants";
import DineIN from "./components/DineIN/DineIN";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Pickup from "./components/Pickup/Pickup";
import ExpandedMenu from "./components/ExpandedMenu/ExpandedMenu";
import Customize from "./components/Customize/Customize";
import MembershipValidation from "./components/MembershipValidation/MembershipValidation";
import Cart from "./components/Cart/Cart";
import Checkout from "./components/Checkout/Checkout";
import OrderPlaced from "./components/OrderPlaced/OrderPlaced";
import OrderStatus from "./components/OrderStatus/OrderStatus";

class AppRoutingComponent extends Component {
  state = {};
  render() {
    return (
      <>
        <Router basename="/FNBApp.Web.UI">
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <Restaurants {...props} />}
            />
            <Route
              path="/pickupvalidation/:restaurantNodeId/:restaurantName"
              render={(props) => <MembershipValidation {...props} />}
            />
            <Route path="/dineIn" render={(props) => <DineIN {...props} />} />
            <Route
              path="/pickup/:restaurantNodeId"
              render={(props) => <Pickup {...props} />}
            />
            <Route
              path="/food-categories/:menuNodeId"
              render={(props) => <ExpandedMenu {...props} />}
            />
            <Route
              path="/food-customization/:nodeId/:customNodeId/:id?"
              render={(props) => <Customize {...props} />}
            />
            <Route
              path="/checkout"
              render={(props) => <Checkout {...props} />}
            />
            <Route
              path="/orderPlaced"
              render={(props) => <OrderPlaced {...props} />}
            />
            <Route
              path="/orderStatus/:restaurantNodeId/:orderId/:salesPointName"
              render={(props) => <OrderStatus {...props} />}
            />
          </Switch>
        </Router>
      </>
    );
  }
}

export default AppRoutingComponent;
