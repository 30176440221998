import { CurrencySymbol, CryptoSecurityKey, SalesCryptoSecurityKey } from "../Utility/Config";
import CryptoJS from "crypto-js";

export const formatPrice = (price) => {
  if (price) {
    return CurrencySymbol + "" + Number(price).toFixed(2);
  } else {
    return "";
  }
};

const encryption = (content) => {
  return CryptoJS.AES.encrypt(content, CryptoSecurityKey).toString();
};

const encryptionWithCBCMode = (text) => {
  // let key = SalesCryptoSecurityKey;
  // let iv = CryptoJS.enc.Utf8.parse(SalesCryptoSecurityKey.substring(SalesCryptoSecurityKey.length - 16));
  // debugger;
  // if (text === '' || text === undefined || text === null) {
  //   return text;
  // }
  
  // const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), SalesCryptoSecurityKey,
  // {
  //     keySize: 128 / 8,
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7
  // });
  // return encrypted.toString();

  if (text === '' || text === undefined || text === null) {
    return text;
  }
  let keys = btoa(SalesCryptoSecurityKey);
  // key = CryptoJS.enc.Utf8.parse(this.keys.substring(0, 16));
  let iv = CryptoJS.enc.Utf8.parse(keys.substring(keys.length - 16));
  const key =  CryptoJS.enc.Utf8.parse(keys.substring(0, 16)); ;// this.generateKey(additionalkey);
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text.toString()), key,
  {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}


const decryption = (cipherText) => {
  let bytes = CryptoJS.AES.decrypt(cipherText, CryptoSecurityKey);
  let result = bytes.toString(CryptoJS.enc.Utf8);
  return result.toString();
};

export const cryptoJS = {
  encryption,
  decryption,
  encryptionWithCBCMode
};
