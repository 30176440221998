import React, { Component, Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Form } from "react-bootstrap";
import { post } from "../../Utility/HttpService";
import { getGiftCardInformation } from "../../Utility/URLService";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GiftCardInformation } from "../../Models/GiftCardInformation";
import UseGiftCard from "./UseGiftCard";

class GiftCardDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: "",
      pin: "",
      validation: {
        status: true,
        message: "",
      },
      balance: 0,
    };
  }

  componentDidMount() {
    this.setState({
      validation: {
        status: true,
        message: "",
      },
      balance: 0,
    });
  }

  handleCheckBalanceClick = () => {
    const cardNumber = this.state.cardNumber;
    const pin = this.state.pin;
    if (cardNumber && pin) {
      const giftCardRequest = {
        cardNumber,
        pin,
      };
      this.getGiftCardInformation(giftCardRequest);
    }
    this.props.handleScreenRefreshTime();
  };
  getGiftCardInformation = (giftCardRequest) => {
    post(getGiftCardInformation, giftCardRequest)
      .then((result) => result.data)
      .then((data: GiftCardInformation) => {
        this.handleGiftCardResponses(data);
      });
  };

  handleGiftCardResponses = (response) => {
    let validation = {
      status: !response.isInvalid,
      message: response.isInvalid ? response.invalidMessage : "",
    };
    this.setState({ validation, balance: response.balance });
  };

  handleChange = (event) => {
    this.props.handleScreenRefreshTime();
    this.setState({ [event.target.id]: event.target.value });
  };

  handleUseGiftCardClick = () => {
    this.props.handleGiftCardBalance(this.state);
    this.props.onClose();
  };

  render() {
    return (
      <Fragment>
        {this.state.balance > 0 && this.getUseGiftCardContainer()}
        {this.state.balance === 0 && this.getGiftCardFormContainer()}
      </Fragment>
    );
  }

  getGiftCardFormContainer = () => {
    return (
      <Dialog
        id="passInfoDialog"
        fullWidth={true}
        maxWidth={false}
        onClose={this.props.onClose}
        aria-labelledby="simple-dialog-title"
        open={this.props.open}
      >
        <DialogTitle id="simple-dialog-title" className="text-center">
          <FontAwesomeIcon
            className="modal-close"
            icon={faTimes}
            onClick={this.props.onClose}
          />
          <div id="headerText">Enter your Gift Card Information</div>
        </DialogTitle>
        <DialogContent>{this.getDialogFormContent()}</DialogContent>
        <DialogActions className="rounded-btns">
          <Button
            variant="danger"
            type="button"
            onClick={this.handleCheckBalanceClick}
          >
            Check Balance
          </Button>
          <Button variant="default" type="button" onClick={this.props.onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  getUseGiftCardContainer = () => {
    let giftCardDetails = {
      balance: this.state.balance,
      onClose: this.props.onClose,
      handleUseGiftCardClick: this.handleUseGiftCardClick,
    };
    return <UseGiftCard {...giftCardDetails} />;
  };

  getDialogFormContent = () => {
    return (
      <Fragment>
        <div className="your-details">
          <Form.Group controlId="cardNumber">
            <Form.Label>Gift Card Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter Gift card Number"
              value={this.state.cardNumber}
              onChange={this.handleChange}
            />
            <p className="required">16 Digit Number Required</p>
          </Form.Group>
          <Form.Group controlId="pin">
            <Form.Label>PIN</Form.Label>
            <Form.Control
              required
              type="tel"
              placeholder="PIN"
              value={this.state.pin}
              onChange={this.handleChange}
            />
            <p className="required">4 Digit Number Required</p>
          </Form.Group>
        </div>
        {!this.state.validation.status && (
          <div className="modal-error-message">
            {this.state.validation.message}
          </div>
        )}
      </Fragment>
    );
  };
}
export default GiftCardDialog;
