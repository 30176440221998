import React, { Component, Fragment } from "react";
import forkknifeclock from "../../assets/images/forkknifeclock.jpg";
import { post } from "../../Utility/HttpService";
import { checkOrderStatus } from "../../Utility/URLService";
import {ItemTreeRoot} from '../../Utility/Config';

class OrderStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      request: {
        restaurantNodeId: props.match.params.restaurantNodeId,
        orderId: props.match.params.orderId,
        salesPointName: props.match.params.salesPointName,
        rootName: ItemTreeRoot,
      },
      orderInfo: {
        firstName: "",
        lastName: "",
        pickUpDetails: "",
        directionLink: "",
        orderStatus: "",
        orderNumber: "",
        pickupTime:"",
      },
    };
    this.getOrderStatus();
  }

  getOrderStatus = () => {
    const request = this.state.request;
    post(checkOrderStatus, request)
      .then((result) => result.data)
      .then((data: any) => {
        const orderInfo = data;
        this.setState({ orderInfo });
      });
  };

  render() {
    return (
      <div id="orderScreen" className="background">
        {this.state.orderInfo.orderStatus === "" &&
          this.getOrderPlacedInformation()}
        {this.state.orderInfo.orderStatus !== "" &&
          this.getReadyForPickupInformation()}
      </div>
    );
  }

  getOrderPlacedInformation = () => {
    return (
      <div className="not-ready">
        <img width="100%" src={forkknifeclock} alt="Fork Knife Clock" />
        <div className="text-center">
          <p>Your Order will be ready:</p>
          {/* <p>{this.state.orderInfo.pickupTime}</p> */}
          <p>Check back soon!</p>
        </div>
      </div>
    );
  };
  getReadyForPickupInformation = () => {
    return (
      <Fragment>
        <div className="ready">
          <h1>Order #{this.state.orderInfo.orderNumber}</h1>
          <h1>
            {this.state.orderInfo.firstName} {this.state.orderInfo.lastName}
          </h1>
          <h1 className="ready-text">Ready For Pickup!</h1>
        </div>
        {/* <p className="content">
          Instruction or more information if needed go here. Veggie sunt bona
          vobis, proinde vos postulo esse
        </p> */}
        <div className="address">
          <p>Pickup Location:</p>
          <p>{this.state.orderInfo.pickUpDetails}</p>
          {this.state.orderInfo.directionLink && (
            <a
              className="get-direction"
              rel="noopener noreferrer"
              target="_blank"
              href={this.state.orderInfo.directionLink}
            >
              Get Directions
            </a>
          )}
        </div>
      </Fragment>
    );
  };
}
export default OrderStatus;
