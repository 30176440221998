import React, { Component } from "react";
import logo from "../../assets/images/logo.png";
import { Navbar } from "react-bootstrap";

class Header extends Component {
  componentDidMount() {
    // console.log("header props:", this.props);
  }
  componentDidUpdate() {
    // console.log("header props:", this.props);
  }
  render() {
    return (
      <div id="header">
        <Navbar>
          <Navbar.Brand href="/">
            <img
              src={logo}
              className="d-inline-block align-top"
              alt="Restaurant logo"
            />
          </Navbar.Brand>
        </Navbar>

        <div className="welcome-section">
          {this.props.appInfo && (
            <>
              <h2>
                {this.props.appInfo.welcomeText
                  ? this.props.appInfo.welcomeText
                  : ""}
              </h2>
              <h6>
                {this.props.appInfo.description
                  ? this.props.appInfo.description
                  : ""}
              </h6>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Header;
