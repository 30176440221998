import { cryptoJS } from "./Common";
import {
  KIOSKSetting,
  Required_CryptoSecurity,
  RestaurantDetailsByNodeId,
} from "./Config";

const getItemFromStorage = (item) => {
  let cipherText = window.sessionStorage.getItem(item);
  let returnValue = "";
  if (Required_CryptoSecurity && cipherText)
    returnValue = cryptoJS.decryption(cipherText);
  else returnValue = cipherText;

  return returnValue;
};
const removeFromStorage = (item) => {
  window.sessionStorage.removeItem(item);
};
const setItemToStorage = (item, content) => {
  let _content = Required_CryptoSecurity
    ? cryptoJS.encryption(content)
    : content;
  window.sessionStorage.setItem(item, _content);
  return true;
};

const updateRestaurantInfo = (restaurantInfo) => {
  setItemToStorage(RestaurantDetailsByNodeId, restaurantInfo);
};

const getRedirectURL = () => {
  let redirectURL = "/";
  let kioskSetting = JSON.parse(getItemFromStorage(KIOSKSetting));
  if (kioskSetting && kioskSetting.IsKioskActive)
    redirectURL = kioskSetting.RedirectUrl;
  return redirectURL;
};

const checkKioskActive = () => {
  let kioskSetting = JSON.parse(getItemFromStorage(KIOSKSetting));
  return kioskSetting && kioskSetting.IsKioskActive
};

export const Storage = {
  setItem: setItemToStorage,
  getItem: getItemFromStorage,
  removeItem: removeFromStorage,
  updateRestaurantInfo,
  getRedirectURL,
  checkKioskActive
};
